import {
  UseMutationResult, // do not remove this import
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  useCompanyData,
  CompanyResponse,
  CompanyRequestParams,
} from '../data/customer/data';
import { RequestParams } from '../types/common-types';
import { useSellerAdminSettings } from './use-settings';
import { CompanyFromApi } from '../types/company';
import { useLocalLanguage } from './use-locale-language';
import { buildCompany } from '../utils/compnay-builder';
import {
  useAccessDeniedNotificationEffect,
  useAccessDeniedNotificationFunction,
} from './use-access-denied';
import { useCallback } from 'react';

export const useSearchCompanies = (params?: RequestParams) => {
  const { getSearchCompaniesQueryFn } = useCompanyData();
  const { data } = useQuery({
    queryKey: ['search-companies', { ...params }],
    queryFn: getSearchCompaniesQueryFn,
    enabled: !!params?.q && params.q.length > 2,
  });
  return { ...data } as const;
};

export const useCompanies = (params?: CompanyRequestParams) => {
  const { getCompanies } = useCompanyData();
  const { SellerFrontSettings } = useSellerAdminSettings();
  const locale = useLocalLanguage();

  const selectCompanies = useCallback(
    (data: CompanyResponse) => ({
      ...data,
      companies: data.companies.map((company: CompanyFromApi) =>
        buildCompany(
          company,
          SellerFrontSettings?.newCustomerDelay ?? 0,
          locale,
        ),
      ),
    }),
    [SellerFrontSettings?.newCustomerDelay, locale],
  );

  const { data, isError, error } = useQuery({
    queryKey: ['companies', { ...params }],
    queryFn: getCompanies,
    select: selectCompanies,
  });

  useAccessDeniedNotificationEffect({
    isError,
    error,
    descriptionKey: 'Common.CannotListCompanies',
    descriptionFallback: "Vous n'êtes pas autorisé à lister les compagnies",
  });

  return { ...data } as const;
};

export const useCompany = (id: string, params?: RequestParams) => {
  const { getCompanyQueryFn } = useCompanyData();
  const { SellerFrontSettings } = useSellerAdminSettings();
  const locale = useLocalLanguage();

  const selectCompany = useCallback(
    (data: CompanyFromApi | null) => {
      return buildCompany(
        data!,
        SellerFrontSettings?.newCustomerDelay ?? 0,
        locale,
      );
    },
    [SellerFrontSettings?.newCustomerDelay, locale],
  );

  const {
    data: company,
    isError,
    error,
  } = useQuery({
    queryKey: ['company', { ...params, id }],
    queryFn: getCompanyQueryFn,
    enabled: !!id,
    select: selectCompany,
  });

  useAccessDeniedNotificationEffect({
    isError,
    error,
    descriptionKey: 'Common.CannotViewCompany',
    descriptionFallback: "Vous n'êtes pas autorisé à consulter cette compagnie",
  });

  return { company } as const;
};

export const useCompanyMutations = () => {
  const {
    updateCompany,
    createCompany,
    updateCustomerPassword,
    addShippingAddressOnCompany,
  } = useCompanyData();
  const queryClient = useQueryClient();
  const notifyAccessDenied = useAccessDeniedNotificationFunction();

  const updateCompanyMutation = useMutation({
    mutationFn: updateCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companies'] });
      queryClient.invalidateQueries({ queryKey: ['company'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotUpdateCompany',
      "Vous n'êtes pas autorisé à modifier cette compagnie",
    ),
  });
  const createCompanyMutation = useMutation({
    mutationFn: createCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['companies'] });
      queryClient.invalidateQueries({ queryKey: ['company'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotCreateCompany',
      "Vous n'êtes pas autorisé à créer une compagnie",
    ),
  });

  const updateCustomerPasswordMutation = useMutation({
    mutationFn: updateCustomerPassword,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
      queryClient.invalidateQueries({ queryKey: ['customer'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotUpdateCustomerPassword',
      "Vous n'êtes pas autorisé à modifier le mot de passe du client",
    ),
  });

  const addShippingAddressOnCompanyMutation = useMutation({
    mutationFn: addShippingAddressOnCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['company'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotAddShippingAddressOnCompany',
      "Vous n'êtes pas autorisé à ajouter une adresse de livraison à cette compagnie",
    ),
  });

  return {
    createCompanyMutation,
    updateCompanyMutation,
    updateCustomerPasswordMutation,
    addShippingAddressOnCompanyMutation,
  } as const;
};
