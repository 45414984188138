import {
  QueryFunction,
  UseMutationResult, // do not remove this import
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { UsersResponse, useUserData } from '../data/users/data';
import {
  useAccessDeniedNotificationEffect,
  useAccessDeniedNotificationFunction,
} from './use-access-denied';
import { RequestParams } from '@/lib/types/common-types';
import { UserApiSchema, UserFromApi } from '@/lib/types/user';
import { useCallback } from 'react';
import { buildUser } from '../utils/user-builder';

export const useUsers = (params?: RequestParams) => {
  const { getUsers } = useUserData();

  const getUsersQueryFn: QueryFunction<
    UsersResponse,
    ['users', RequestParams]
  > = async ({ queryKey }) => {
    const params = queryKey?.[1];
    return getUsers(params);
  };

  const transformedData = useCallback(
    (data: UsersResponse) => ({
      ...data,
      users: data.users
        .map((user: UserFromApi) => {
          const parsedUser = UserApiSchema.parse(user);
          return buildUser(parsedUser)!;
        })
        .toSorted((a, b) => a.label.localeCompare(b.label)),
    }),
    [],
  );

  const { data, isError, error } = useQuery({
    queryKey: ['users', { ...params }],
    queryFn: getUsersQueryFn,
    select: transformedData,
  });

  useAccessDeniedNotificationEffect({
    isError,
    error,
    descriptionKey: 'Common.CannotListCompanies',
    descriptionFallback: "Vous n'êtes pas autorisé à lister les utilisateurs",
  });

  return { ...data } as const;
};

export const useUserMutations = () => {
  const { postUsers, updateUsers, deleteUsers } = useUserData();
  const queryClient = useQueryClient();
  const notifyAccessDenied = useAccessDeniedNotificationFunction();

  const createUserMutation = useMutation({
    mutationFn: postUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotCreateUser',
      "Vous n'êtes pas autorisé à créer un utilisateur",
    ),
  });

  const updateUserMutation = useMutation({
    mutationFn: updateUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotUpdateUser',
      "Vous n'êtes pas autorisé à modifier un utilisateur",
    ),
  });

  const deleteUserMutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] });
    },
    onError: notifyAccessDenied(
      'Common.CannotDeleteUser',
      "Vous n'êtes pas autorisé à supprimer un utilisateur",
    ),
  });

  return {
    createUserMutation,
    updateUserMutation,
    deleteUserMutation,
  } as const;
};
