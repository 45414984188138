import { useToast } from '@medusajs/ui';
import { useEffect } from 'react';
import { useCatalogTranslation } from './use-catalog-translations';
import { RequestError } from '../types/errors';
export const useAccessDeniedNotificationEffect = ({
  isError,
  error,
  descriptionKey,
  descriptionFallback,
}: {
  isError: boolean;
  error: Error | null;
  descriptionKey: `Common.${string}`;
  descriptionFallback: string;
}) => {
  const { toast } = useToast();
  const { ct } = useCatalogTranslation(['admin_common']);

  useEffect(() => {
    if (isError) {
      if ((error as RequestError).status === 403) {
        toast({
          variant: 'error',
          title: ct('Common.AccessDenied', 'Accès refusé'),
          description: ct(descriptionKey, descriptionFallback),
          duration: 7000,
        });
      }
    }
  }, [isError, error]);
};

export const useAccessDeniedNotificationFunction = () => {
  const { toast } = useToast();
  const { ct } = useCatalogTranslation(['admin_common']);
  return (descriptionKey: `Common.${string}`, descriptionFallback: string) =>
    (error: Error) => {
      if ((error as RequestError).status === 403) {
        toast({
          variant: 'error',
          title: ct('Common.AccessDenied', 'Accès refusé'),
          description: ct(descriptionKey, descriptionFallback),
          duration: 7000,
        });
      }
    };
};
