import {
  CompanyApiSchema,
  CompanyFromApi,
  PostCompanyPayload,
  PostShippingAddressPayload,
} from '@/lib/types/company';
import { ListApiResponse } from '../data';
import { QueryFunction } from '@tanstack/react-query';
import { RequestParams } from '@/lib/types/common-types';
import { useCatalogRequest } from '../../hooks/use-catalog-request';

export type CompanyResponse = ListApiResponse & {
  companies: CompanyFromApi[];
};

export type SearchCompanyResponse = {
  hits: CompanyFromApi[];
  filters: Record<string, Record<string, number | null>>;
  total: number;
  has_next_page: boolean;
};
export type CompanyRequestParams = RequestParams & {
  is_active?: boolean;
};

export const useCompanyData = () => {
  const { catalogRequest } = useCatalogRequest();

  const getCompanies: QueryFunction<
    CompanyResponse,
    ['companies', RequestParams]
  > = async ({ queryKey }) => {
    let result: (ListApiResponse & { companies: CompanyFromApi[] }) | null =
      null;
    const path = '/companies';
    try {
      const params: RequestParams = queryKey[1];
      result = await catalogRequest('GET', path, {
        query: params,
      }).then((res) => {
        const { body } = res;
        const companies: CompanyFromApi[] = body.companies;
        const parsedCompanies = companies.map((company: CompanyFromApi) =>
          CompanyApiSchema.parse(company),
        );
        parsedCompanies.sort((a, b) => a.name.localeCompare(b.name));
        return {
          ...body,
          companies: parsedCompanies,
        };
      });
    } catch (error) {
      console.error('Failed to fetch companies ', error);
    }
    if (!result) {
      return {
        count: 0,
        offset: 0,
        limit: 0,
        companies: [],
      };
    }
    return result!;
  };

  const getSearchCompaniesQueryFn: QueryFunction<
    SearchCompanyResponse,
    ['search-companies', RequestParams]
  > = async ({ queryKey }) => {
    const params = queryKey[1];
    return getSearchCompanies(params);
  };

  const getSearchCompanies = async (
    params: RequestParams,
  ): Promise<SearchCompanyResponse> => {
    const path = '/companies/search';
    const response = await catalogRequest('POST', path, { body: params });
    return response.body as SearchCompanyResponse;
  };

  const getCompanyQueryFn: QueryFunction<
    CompanyFromApi | null,
    ['company', RequestParams & { id: string }]
  > = async ({ queryKey }) => {
    const id = queryKey[1].id;
    return getCompanyById(id);
  };

  const getCompanyById = async (id: string): Promise<CompanyFromApi | null> => {
    try {
      const result = await catalogRequest('GET', `/companies/${id}`).then(
        (res) => {
          const { body } = res;
          const company: CompanyFromApi = body.company;
          try {
            const parsedCompany = CompanyApiSchema.parse(company);
            return parsedCompany;
          } catch (e) {
            throw new Error('Validation error on company data');
          }
        },
      );
      return result;
    } catch (error) {
      console.error('Failed to fetch company ', error);
      return null;
    }
  };

  const createCompany = async (
    payload: PostCompanyPayload,
  ): Promise<CompanyFromApi> => {
    const path = '/companies';
    const response = await catalogRequest('POST', path, {
      body: payload,
    });
    const company = CompanyApiSchema.parse(response.body.company);
    return company;
  };

  const updateCompany = async ({
    payload,
    id,
  }: {
    payload: PostCompanyPayload;
    id: string;
  }): Promise<CompanyFromApi> => {
    const path = `/companies/${id}`;
    const response = await catalogRequest('POST', path, {
      body: payload,
    });
    const company = CompanyApiSchema.parse(response.body.company);
    return company;
  };

  async function updateCustomerPassword({
    id,
    password,
  }: {
    id: string;
    password: string;
  }) {
    const path = `/customers/${id}`;
    const response = await catalogRequest('POST', path, {
      body: { password: password },
    });
    return response.body.customer;
  }

  const addShippingAddressOnCompany = async ({
    id,
    payload,
  }: {
    id: string;
    payload: PostShippingAddressPayload;
  }) => {
    const path = `/companies/${id}/shipping-addresses`;
    const response = await catalogRequest('POST', path, {
      body: payload,
    });
    return response.body.shipping_address;
  };

  return {
    getCompanies,
    getSearchCompaniesQueryFn,
    getCompanyQueryFn,
    getCompanyById,
    createCompany,
    updateCompany,
    updateCustomerPassword,
    addShippingAddressOnCompany,
  };
};
