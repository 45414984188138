'use client';

import { useRouter } from 'next/navigation';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCatalogTranslation } from '@hooks/use-catalog-translations';
import { useCompanyMutations } from '@hooks/use-company';
import { Check, SendDiagonal, Xmark, RefreshDouble, Star } from 'iconoir-react';
import {
  SmartOrder,
  SmartOrdersColumnData,
  DraftOrderTypeEnum,
} from '../types/smart-orders';
import {
  SmartOrderErrorEnum,
  SmartOrderStatus,
} from '@startcatalog/catalog-types-package';
import {
  useSmartOrder,
  useSmartOrderMutations,
  useSmartOrders,
} from '../hooks/use-smart-orders';
import { useToast } from '@medusajs/ui';
import { User } from '../types/user';
import { useAccount } from './account-context';
import { useUsers } from '../hooks/use-users';
import { CartFromApi } from '../types/cart';
import { missingItem } from '../types/product';
import { useSellerAdminSettings } from '../hooks/use-settings';
import { Address } from '../types/address';

interface ISmartOrdersContext {
  columns: SmartOrdersColumnData[];
  getCtaLabel: (status: SmartOrderStatus) => string;
  handleClickOnCard: (
    type: SmartOrderStatus,
    id: string,
    purchase_url?: string,
  ) => void;
  handleUpdateSmartOrder: (
    payload: {
      customer_id?: string;
      external_id?: string;
      company_id?: string;
      type?: DraftOrderTypeEnum;
      metadata?: Record<string, unknown>;
      shipping_address?: Address | string;
      update_cart?: boolean;
    },
    smartOrderId?: string,
  ) => Promise<void>;
  handleCancelSmartOrder: (id: string) => Promise<void>;
  handleCompleteSmartOrder: (id: string) => Promise<void>;
  getErrorMessage: (error: SmartOrderErrorEnum) => string;
  setCurrentSmartOrderId: (id: string | null) => void;
  currentSmartOrder: SmartOrder | undefined;
  isLoading: boolean;
  editCustomer: boolean;
  setEditCustomer: (value: boolean) => void;
  alternateNamesModalOpened: boolean;
  setAlternateNamesModalOpened: (value: boolean) => void;
  finalizeSendToErp: (
    id: string,
    needRedirect?: boolean,
    uniqueSmartOrder?: boolean,
  ) => Promise<void>;
  addAlternateName: (name: string) => Promise<void>;
  filteredAssigneeIds: (string | null)[];
  setFilteredAssigneeIds: (values: (string | null)[]) => void;
  availableAssignees: User[];
  smartOrders: SmartOrder[];
  missingItemPairs: {
    missingItem: missingItem;
    cartItem: NonNullable<CartFromApi['items']>[number];
  }[];
  addProductVariantAlternateNames: (
    vans: {
      variant_id: string;
      name: string;
    }[],
  ) => Promise<void>;
}

const initialData: ISmartOrdersContext = {
  columns: [],
  getCtaLabel: () => '',
  handleUpdateSmartOrder: async () => {},
  handleClickOnCard: () => {},
  handleCancelSmartOrder: async () => {},
  getErrorMessage: () => '',
  handleCompleteSmartOrder: async () => {},
  finalizeSendToErp: async () => {},
  setCurrentSmartOrderId: () => {},
  currentSmartOrder: undefined,
  isLoading: false,
  editCustomer: false,
  setEditCustomer: () => {},
  alternateNamesModalOpened: false,
  setAlternateNamesModalOpened: () => {},
  addAlternateName: async () => {},
  filteredAssigneeIds: [],
  setFilteredAssigneeIds: () => {},
  availableAssignees: [],
  smartOrders: [],
  missingItemPairs: [],
  addProductVariantAlternateNames: async () => {},
};

export const SmartOrdersContext =
  createContext<ISmartOrdersContext>(initialData);

export const SmartOrdersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const { toast } = useToast();
  const { ct } = useCatalogTranslation('admin_orders');
  const [editCustomer, setEditCustomer] = useState(false);
  const [availableAssignees, setAvailableAssignees] = useState<User[]>([]);
  const [filteredAssigneeIds, setFilteredAssigneeIdsInternal] = useState<
    (string | null)[]
  >([]);
  const [alternateNamesModalOpened, setAlternateNamesModalOpened] =
    useState(false);
  const { smartOrders } = useSmartOrders(filteredAssigneeIds);
  const {
    cancelSmartOrderMutation: cancel,
    updateDraftOrderMutation: update,
    convertDraftToOrderMutation: convert,
  } = useSmartOrderMutations();
  const { updateCompanyMutation: updateCompany } = useCompanyMutations();
  const { SmartOrderSettings } = useSellerAdminSettings();

  const baseColumns = useMemo(() => {
    const columns: SmartOrdersColumnData[] = [
      {
        type: SmartOrderStatus.PROCESSING,
        title: ct('SmartOrders.columnProcessing', 'En cours de traitement'),
        titleBadgeType: 'gray',
        icon: <RefreshDouble />,
        tooltip: ct(
          'SmartOrders.columnProcessingTooltip',
          'Processing Tooltip',
        ),
        description: ct(
          'SmartOrders.columnProcessingDescription',
          'Nous avons bien reçu ces commandes. Durée de traitement: < 3 minutes',
        ),
        smartOrders: [],
        testId: 'processing',
      },
      {
        type: SmartOrderStatus.FAILED,
        title: ct('SmartOrders.columnFail', 'Échec de traitement'),
        titleBadgeType: 'red',
        icon: <Xmark />,
        tooltip: ct('SmartOrders.columnFailTooltip', 'Fail Tooltip'),
        description: ct(
          'SmartOrders.columnFailDescription',
          "Malheureusement, nous n'avons pas pu traiter ces commandes. Elles dovient être saises dans votre ERP.",
        ),
        smartOrders: [],
        testId: 'failed',
      },
      {
        type: SmartOrderStatus.REQUIRES_ACTION,
        title: ct('SmartOrders.columnToVerify', 'Commandes à compléter'),
        titleBadgeType: 'blue',
        icon: <Check />,
        tooltip: ct(
          'SmartOrders.columnToVerifyTooltip',
          'Order is ready Tooltip',
        ),
        description: ct(
          'SmartOrders.columnToVerifyDescription',
          'Nous avons traité ces commandes, juste quelques informations à compléter avant envoi dans votre ERP.',
        ),
        smartOrders: [],
        testId: 'requires_action',
      },
    ];

    if (SmartOrderSettings?.opportunityDetectionEnabled) {
      columns.push({
        type: SmartOrderStatus.OPPORTUNITY_DETECTION,
        title: ct('SmartOrders.columnOpportunities', 'Opportunités'),
        titleBadgeType: 'yellow',
        icon: <Star />,
        tooltip: ct(
          'SmartOrders.columnOpportunitiesTooltip',
          'Opportunities Tooltip',
        ),
        description: ct(
          'SmartOrders.columnOpportunitiesDescription',
          'Opportunities Description',
        ),
        smartOrders: [],
        testId: 'opportunities',
      });
    }

    columns.push({
      type: SmartOrderStatus.SENT,
      title: ct('SmartOrders.columnOrderSent', "Commandes transmises à l'ERP"),
      titleBadgeType: 'green',
      icon: <SendDiagonal />,
      tooltip: ct(
        'SmartOrders.columnOrderSentTooltip',
        'Order sent to ERP Tooltip',
      ),
      description: ct(
        'SmartOrders.columnOrderSentDescription',
        'Nous avons transmis ces commandes à votre ERP. Elles disparaitront une fois validée dans votre ERP.',
      ),
      smartOrders: [],
      testId: 'sent',
    });

    return columns;
  }, [SmartOrderSettings?.opportunityDetectionEnabled, ct]);

  const [columns, setColumns] = useState<SmartOrdersColumnData[]>(
    () => baseColumns as SmartOrdersColumnData[],
  );

  useEffect(() => {
    if (smartOrders) {
      setColumns(distributeSmartOrders(smartOrders));
    }
  }, [smartOrders]);

  const distributeSmartOrders = useCallback(
    (smartOrders: SmartOrder[]) => {
      return baseColumns.map((column) => {
        const updatedSmartOrders = smartOrders.filter(
          (smartOrder) => smartOrder.status === column.type,
        );
        return { ...column, smartOrders: updatedSmartOrders };
      });
    },
    [baseColumns],
  );

  const [currentSmartOrderId, setCurrentSmartOrderId] = useState<string | null>(
    null,
  );

  const { smartOrder: currentSmartOrder, isLoading } =
    useSmartOrder(currentSmartOrderId);

  useEffect(() => {
    if (
      currentSmartOrder &&
      !currentSmartOrder.isToComplete &&
      !currentSmartOrder.hasOpportunities
    ) {
      router.push('/smartorder');
    }
  }, [currentSmartOrder]);

  const nextSmartOrderId = useMemo(() => {
    const currentType = currentSmartOrder?.status;
    let smartOrderIds: string[] = [];

    if (currentType === SmartOrderStatus.REQUIRES_ACTION) {
      const toCompleteIds = smartOrders
        ?.filter((smartOrder) => smartOrder.isToComplete)
        .map((smartOrder) => smartOrder.id);

      if (
        !toCompleteIds?.length ||
        (toCompleteIds.length === 1 && toCompleteIds[0] === currentSmartOrderId)
      ) {
        return null;
      }
      smartOrderIds = [...toCompleteIds];
    } else if (currentType === SmartOrderStatus.OPPORTUNITY_DETECTION) {
      const opportunityIds = smartOrders
        ?.filter((smartOrder) => smartOrder.hasOpportunities)
        .map((smartOrder) => smartOrder.id);

      if (
        !opportunityIds?.length ||
        (opportunityIds.length === 1 &&
          opportunityIds[0] === currentSmartOrderId)
      ) {
        return null;
      }
      smartOrderIds = [...opportunityIds];
    }

    if (!currentSmartOrderId) {
      return smartOrderIds[0];
    }

    const currentIndex = smartOrderIds.indexOf(currentSmartOrderId);

    return smartOrderIds[(currentIndex + 1) % smartOrderIds.length];
  }, [currentSmartOrderId, smartOrders]);

  const getCtaLabel = (status: SmartOrderStatus): string => {
    switch (status) {
      case SmartOrderStatus.FAILED:
        return ct('SmartOrders.ctaFail', 'Télécharger et Archiver');
      case SmartOrderStatus.REQUIRES_ACTION:
        return ct('SmartOrders.ctaToComplete', 'Compléter');
      case SmartOrderStatus.OPPORTUNITY_DETECTION:
        return ct('SmartOrders.ctaOpportunities', 'Consulter');
      default:
        return '';
    }
  };

  const getErrorMessage = (error: SmartOrderErrorEnum): string => {
    switch (error) {
      case SmartOrderErrorEnum.MISSING_CUSTOMER:
        return ct('SmartOrders.errorMissingCustomer', 'Client non identifié');
      case SmartOrderErrorEnum.MISSING_ORDER_ID:
        return ct(
          'SmartOrders.errorMissingOrderId',
          'Numéro de commande manquant',
        );
      case SmartOrderErrorEnum.MISSING_ITEMS:
        return ct('SmartOrders.errorMissingItems', 'Produits manquants');
      case SmartOrderErrorEnum.NO_ITEMS:
        return ct('SmartOrders.errorNoItems', 'Produits manquants');
      case SmartOrderErrorEnum.ITEM_REFERENCE_ERROR:
        return ct('SmartOrders.errorLinesErrors', 'Erreurs sur les produits');
      case SmartOrderErrorEnum.ITEM_CATALOG_PRICE_ERROR:
      case SmartOrderErrorEnum.ITEM_UNIT_PRICE_ERROR:
      case SmartOrderErrorEnum.ITEM_TOTAL_PRICE_ERROR:
        return ct('SmartOrders.errorPriceLineError', 'Erreurs sur les prix');
      case SmartOrderErrorEnum.VARIANT_NOT_IN_CATALOG:
        return ct('SmartOrders.errorMissingPriceError', 'Prix manquant');
      case SmartOrderErrorEnum.ITEM_QUANTITY_ERROR:
        return ct('SmartOrders.errorQuantityError', 'Erreur sur les quantités');
      case SmartOrderErrorEnum.MISSING_BILLING_ADDRESS:
        return ct(
          'SmartOrders.missingBillingAddressError',
          'Adresse de facturation manquante',
        );
      case SmartOrderErrorEnum.MISSING_SHIPPING_ADDRESS ||
        SmartOrderErrorEnum.WRONG_SHIPPING_ADDRESS ||
        SmartOrderErrorEnum.WRONG_DELIVERY_METHOD:
        return ct(
          'SmartOrders.missingShippingAddressError',
          'Adresse de livraison manquante',
        );
      case SmartOrderErrorEnum.WRONG_BILLING_ADDRESS:
        return ct(
          'SmartOrders.wrongBillingddressError',
          'Adresse de facturation erronée',
        );
      case SmartOrderErrorEnum.WRONG_SHIPPING_ADDRESS:
        return ct(
          'SmartOrders.wrongShippingAddressError',
          'Adresse de livraison erronée',
        );
      case SmartOrderErrorEnum.MISSING_COMPANY:
        return ct(
          'SmartOrders.missingCompanyError',
          'Compagnie non identifiée',
        );
      case SmartOrderErrorEnum.DUPLICATE_SMART_ORDER:
        return ct(
          'SmartOrders.duplicateSmartOrder',
          'Commande identique déjà existante',
        );
      case SmartOrderErrorEnum.AI_REVIEWER_FAILED:
        return ct(
          'SmartOrders.aiReviewerFailed',
          "Arthur a détecté les informations suivantes, mais a besoin de votre vérification pour être certain qu'elles sont justes",
        );
      case SmartOrderErrorEnum.DOCUMENT_TYPE_MISMATCH:
        return ct(
          'SmartOrders.documentTypeMismatch',
          'Le type de document est incorrect',
        );
      case SmartOrderErrorEnum.GOVERNMENT_PURCHASE_ORDER_ERROR:
        return ct(
          'SmartOrders.governmentPurchaseOrderError',
          'Erreur sur une commande publique',
        );
      case SmartOrderErrorEnum.ITEM_UNIT_TYPE_ERROR:
        return ct('SmartOrders.itemUnitTypeError', 'Mauvaise unité de produit');
      case SmartOrderErrorEnum.PRODUCT_FOUND_BY_SEARCH_NAME_AI:
        return ct(
          'SmartOrders.productFoundBySearchNameAi',
          'Vérification nécessaire',
        );
      default:
        return ct(
          'SmartOrders.errorDefault',
          'Une erreur est survenue dans le traitement de votre commande',
        );
    }
  };

  const handleClickOnCard = (
    status: SmartOrderStatus,
    id: string,
    purchaseUrl?: string,
  ) => {
    switch (status) {
      case SmartOrderStatus.REQUIRES_ACTION:
      case SmartOrderStatus.OPPORTUNITY_DETECTION:
        router.push(`/smartorder/${id}`);
        break;
      case SmartOrderStatus.FAILED:
        if (purchaseUrl && purchaseUrl !== '' && window !== undefined) {
          window.open(purchaseUrl, '_blank');
        }
        handleCancelSmartOrder(id);
        break;
      case SmartOrderStatus.SENT: {
        const smartOrder = smartOrders?.find((sm) => sm.id === id);
        if (smartOrder?.smartOrderType === DraftOrderTypeEnum.QUOTE_REQUEST) {
          window.open(`/quotes/${smartOrder.id}`, '_blank');
        } else if (smartOrder?.orderId) {
          window.open(`/orders/${smartOrder.orderId}`, '_blank');
        } else {
          toast({
            variant: 'error',
            title: ct(
              'SmartOrders.orderNotFoundError',
              'Commande non retrouvée',
            ),
            description: ct(
              'SmartOrders.orderNotFoundErrorDesc',
              "Nous n'avons pas retrouvé la commande liée à ce Smart Order",
            ),
            duration: 3000,
          });
        }
        break;
      }
    }
  };

  const handleCancelSmartOrder = async (id: string) => {
    await cancel.mutateAsync(
      { id },
      {
        onSuccess: () => {
          toast({
            variant: 'success',
            title: ct('SmartOrders.cancelSuccess', 'Commande annulée'),
            description: ct(
              'SmartOrders.cancelSuccessDesc',
              'Votre commande a été annulée avec succès',
            ),
            duration: 3000,
          });
          router.push('/smartorder');
        },
        onError: () => {
          toast({
            variant: 'error',
            title: ct('SmartOrders.cancelError', 'Une erreur est survenue'),
            description: ct(
              'SmartOrders.cancelErrorDesc',
              "Nous n'avons pas pu annuler votre commande. Veuillez réessayer ou contacter le support",
            ),
            duration: 3000,
          });
        },
      },
    );
  };

  const missingItemPairs = useMemo(() => {
    if (!SmartOrderSettings?.productVariantAlternateNamesEnabled) {
      return [];
    }
    const missingItems: missingItem[] | undefined =
      currentSmartOrder?.errors?.find(
        (error) => error.type === SmartOrderErrorEnum.MISSING_ITEMS,
      )?.data?.missingItemsList as missingItem[];

    const missingItemsByRank = new Map(
      missingItems?.map((item) => [`${item.rank}`, item]),
    );

    const res = currentSmartOrder?.cart?.items
      ?.filter((item) => item && missingItemsByRank.has(`${item.rank}`))
      .map((item) => ({
        missingItem: missingItemsByRank.get(`${item.rank}`)!,
        cartItem: item,
      }));

    return res;
  }, [currentSmartOrder?.errors, currentSmartOrder?.cart?.items]);

  const handleUpdateSmartOrder = async (
    payload: {
      customer_id?: string;
      external_id?: string;
      company_id?: string;
      type?: DraftOrderTypeEnum;
      metadata?: Record<string, unknown>;
      shipping_address?: Address | string;
      update_cart?: boolean;
    },
    smartOrderId?: string,
  ) => {
    await update.mutateAsync(
      { id: smartOrderId || currentSmartOrderId!, payload },
      {
        onError: () => {
          const concernClient = !!payload.customer_id || !!payload.company_id;
          const description = concernClient
            ? ct(
                'SmartOrders.updateCustomerErrorDesc',
                "Nous n'avons pas pu mettre à jour le client de votre SmartOrder. Veuillez réessayer ou contacter le support",
              )
            : ct(
                'SmartOrders.updateExternalIdErrorDesc',
                "Nous n'avons pas pu mettre à jour le numéro de commande. Veuillez réessayer ou contacter le support",
              );

          toast({
            variant: 'error',
            title: ct('SmartOrders.updateError', 'Une erreur est survenue'),
            duration: 3000,
            description,
          });
        },
      },
    );

    if (payload.customer_id || payload.company_id) {
      setEditCustomer(false);
    }
  };

  const handleCompleteSmartOrder = async (id: string) => {
    if (
      (!currentSmartOrder?.cart?.customer_id &&
        !currentSmartOrder?.cart?.company_id) ||
      !currentSmartOrder?.cart?.external_id
    ) {
      toast({
        variant: 'error',
        title: ct('SmartOrders.completeError', 'Informations manquantes'),
        description: ct(
          'SmartOrders.completeErrorDesc',
          'Veuillez sélectionner un client et renseigner le numéro de commande',
        ),
        duration: 3000,
      });
      return;
    }

    // Check that the company name found during smart order processing is not the same as the company name in the cart or its alternative names
    const hasMissingCompanyOrCustomerError = currentSmartOrder?.errors?.some(
      (error) =>
        error.type === SmartOrderErrorEnum.MISSING_COMPANY &&
        error.data?.purchaseOrderCompanyName &&
        error.data?.purchaseOrderCompanyName !==
          currentSmartOrder?.cart?.company?.name &&
        !(
          (currentSmartOrder?.cart?.company?.metadata
            ?.alternative_names as string[]) || []
        ).includes(error.data?.purchaseOrderCompanyName as string),
    );
    const hasMissingItemPairs =
      missingItemPairs && missingItemPairs?.length > 0;
    if (hasMissingCompanyOrCustomerError || hasMissingItemPairs) {
      setAlternateNamesModalOpened(true);
    } else {
      finalizeSendToErp(id);
    }
  };

  const finalizeSendToErp = async (
    id: string,
    needRedirect = true,
    uniqueSmartOrder = true,
  ) => {
    if (
      currentSmartOrder?.smartOrderType === DraftOrderTypeEnum.QUOTE_REQUEST
    ) {
      sendQuoteRequest(id, needRedirect, uniqueSmartOrder);
    } else {
      convertSmartOrder(id, needRedirect, uniqueSmartOrder);
    }
  };

  const convertSmartOrder = async (
    id: string,
    needRedirect: boolean,
    uniqueSmartOrder: boolean,
  ) => {
    await convert.mutateAsync(
      { id },
      {
        onSuccess: () => {
          if (uniqueSmartOrder) {
            setAlternateNamesModalOpened(false);
          }
          toast({
            variant: 'success',
            title: ct('SmartOrders.convertSuccess', 'Commande créee'),
            description: ct(
              'SmartOrders.convertlSuccessDesc',
              'Votre commande a été créee et envoyée vers votre ERP',
            ),
            duration: 3000,
          });
          if (needRedirect) {
            nextSmartOrderToComplete();
          }
        },
        onError: (error) => {
          let description: string | undefined = undefined;

          const axiosError = error as unknown as { status: number };

          if (axiosError?.status === 422) {
            description = `${ct(
              'SmartOrders.duplicateSmartOrderError',
              'Une commande identique existe déjà ; N° de commande:',
            )} ${currentSmartOrder?.cart?.external_id}`;
          }
          setAlternateNamesModalOpened(false);
          toast({
            variant: 'error',
            title: ct('SmartOrders.convertError', 'Une erreur est survenue'),
            description: description,
            duration: 3000,
          });
        },
      },
    );
  };

  const sendQuoteRequest = async (
    id: string,
    needRedirect: boolean,
    uniqueSmartOrder: boolean,
  ) => {
    const payload = {
      metadata: {
        smart_order_status: SmartOrderStatus.SENT,
      },
    };
    await update.mutateAsync(
      { id, payload },
      {
        onSuccess: () => {
          if (uniqueSmartOrder) {
            setAlternateNamesModalOpened(false);
          }
          toast({
            variant: 'success',
            title: ct(
              'SmartOrders.validateQuoteRequestSuccess',
              'Demande de devis envoyée',
            ),
            description: ct(
              'SmartOrders.validateQuoteRequestSuccessDesc',
              'Votre demande de devis a été envoyée avec succès',
            ),
            duration: 3000,
          });
          if (needRedirect) {
            nextSmartOrderToComplete();
          }
        },
        onError: () => {
          toast({
            variant: 'error',
            title: ct(
              'SmartOrders.validateQuoteRequestError',
              'Une erreur est survenue',
            ),
            description: ct(
              'SmartOrders.validateQuoteRequestErrorDesc',
              "Nous n'avons pas pu envoyer votre demande de devis. Veuillez réessayer ou contacter le support",
            ),
            duration: 3000,
          });
        },
      },
    );
  };

  const addAlternateName = async (name: string) => {
    const company = currentSmartOrder?.cart?.company;
    if (!company) return;

    try {
      await updateCompany.mutateAsync({
        id: company.id,
        payload: {
          alternate_name: name,
          draft_order_id: currentSmartOrder?.id,
        },
      });
    } catch (error) {
      toast({
        variant: 'error',
        title: ct(
          'SmartOrders.addAlternateNameError',
          "Nous n'avons pas pu ajouter le nom alternatif",
        ),
        description: ct(
          'SmartOrders.addAlternateNameErrorDesc',
          'Veuillez réessayer ou contacter le support',
        ),
        duration: 3000,
      });
    }
  };

  const addProductVariantAlternateNames = async (
    vans: {
      variant_id: string;
      name: string;
    }[],
  ) => {
    if (!currentSmartOrder?.cart?.company?.id) return;
    try {
      await updateCompany.mutateAsync({
        id: currentSmartOrder?.cart?.company?.id,
        payload: {
          product_variant_alternate_names: vans,
        },
      });
    } catch (error) {
      toast({
        variant: 'error',
        title: ct(
          'SmartOrders.addProductVariantAlternateNameError',
          "Nous n'avons pas pu ajouter le(s) nom(s) alternatif(s)",
        ),
        description: ct(
          'SmartOrders.addProductVariantAlternateNameErrorDesc',
          'Veuillez réessayer ou contacter le support',
        ),
      });
    }
  };

  const nextSmartOrderToComplete = () => {
    if (nextSmartOrderId) {
      router.replace(`/smartorder/${nextSmartOrderId}`);
    } else {
      router.push('/smartorder');
    }
  };

  const { users } = useUsers({ permission: 'can_view_smartorders' });
  const { email: myEmail } = useAccount();
  useEffect(() => {
    if (users) {
      const sortedAgents = users.toSorted((a, b) => {
        if (a.email === myEmail) {
          return -1;
        }
        if (b.email === myEmail) {
          return 1;
        }
        return a.label.localeCompare(b.label);
      });
      setAvailableAssignees([{ id: null }, ...sortedAgents] as User[]);
    }
  }, [users, myEmail]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const assigneeString = params.has('assignees')
      ? params.get('assignees')
      : localStorage.getItem('smart-orders-assignees');

    const assigneeIds =
      assigneeString?.split(',').map((a) => (a.trim() === 'null' ? null : a)) ||
      [];

    setFilteredAssigneeIdsInternal(assigneeIds);
  }, []);

  const setFilteredAssigneeIds = useCallback(
    (assigneeIds: (string | null)[]) => {
      const params = new URLSearchParams(window.location.search);

      if (assigneeIds.length > 0) {
        const assigneeString = assigneeIds.map((a) => a || 'null').join(',');
        params.set('assignees', assigneeString);
        localStorage.setItem('smart-orders-assignees', assigneeString);
      } else {
        params.delete('assignees');
        localStorage.removeItem('smart-orders-assignees');
      }

      const newUrl = `${window.location.pathname}${
        params.toString() ? '?' + params.toString() : ''
      }`;
      router.replace(newUrl, { scroll: false });
      setFilteredAssigneeIdsInternal(assigneeIds);
    },
    [router],
  );

  return (
    <SmartOrdersContext.Provider
      value={{
        columns,
        getCtaLabel,
        handleClickOnCard,
        getErrorMessage,
        handleUpdateSmartOrder,
        handleCancelSmartOrder,
        handleCompleteSmartOrder,
        finalizeSendToErp,
        setCurrentSmartOrderId,
        currentSmartOrder,
        isLoading,
        editCustomer,
        setEditCustomer,
        alternateNamesModalOpened,
        setAlternateNamesModalOpened,
        addAlternateName,
        filteredAssigneeIds,
        setFilteredAssigneeIds,
        availableAssignees,
        smartOrders: smartOrders ?? [],
        missingItemPairs: missingItemPairs ?? [],
        addProductVariantAlternateNames,
      }}
    >
      {children}
    </SmartOrdersContext.Provider>
  );
};

export const useSmartOrdersContext = () => {
  const context = useContext(SmartOrdersContext);
  if (context === undefined) {
    throw new Error(
      'useSmartOrdersContext must be used within a SmartOrdersProvider',
    );
  }
  return context;
};
