import {
  UserApiSchema,
  UserFromApi,
  PostUserFromApi,
  UpdateUserFromApi,
} from '@/lib/types/user';
import { ListApiResponse } from '../data';
import { useCatalogRequest } from '../../hooks/use-catalog-request';
import { QueryFunction } from '@tanstack/react-query';
import { RequestParams } from '@/lib/types/common-types';

export type UsersResponse = ListApiResponse & {
  users: UserFromApi[];
};

export const useUserData = () => {
  const { catalogRequest } = useCatalogRequest();

  const getUsersQueryFn: QueryFunction<
    UsersResponse,
    ['users', RequestParams]
  > = async ({ queryKey }) => {
    const params = queryKey?.[1];
    return getUsers(params);
  };

  const getUsers = async (params?: RequestParams): Promise<UsersResponse> => {
    const path = '/users';
    try {
      const result = await catalogRequest('GET', path, {
        query: params || {},
      });
      const userResponse = result.body as UsersResponse;
      userResponse.users = userResponse.users.filter(
        (user) =>
          user.deleted_at === null &&
          user.email !== 'import@startcatalog.com' &&
          user.email !== 'help@startcatalog.com',
      );
      return userResponse;
    } catch (error) {
      console.error('Failed to fetch users ', error);
    }

    return {
      count: 0,
      offset: 0,
      limit: 0,
      users: [],
    };
  };

  const postUsers = async (payload: PostUserFromApi): Promise<UserFromApi> => {
    const path = '/users';
    const response = await catalogRequest('POST', path, {
      body: payload,
    });
    const user = UserApiSchema.parse(response.body.user);
    return user;
  };

  const updateUsers = async ({
    id,
    payload,
  }: {
    id: string;
    payload: UpdateUserFromApi;
  }): Promise<UserFromApi> => {
    const path = `/users/${id}`;
    const response = await catalogRequest('POST', path, {
      body: payload,
    });
    const user = UserApiSchema.parse(response.body.user);
    return user;
  };

  const deleteUsers = async (id: string): Promise<void> => {
    const path = `/users/${id}`;
    await catalogRequest('DELETE', path);
  };

  return {
    getUsers,
    postUsers,
    updateUsers,
    deleteUsers,
    getUsersQueryFn,
  };
};
